$(function () {
    initNav();
    initMatchHeight();
    initSubscribe();
});

$(window).resize(function () {
    clearTimeout($.data(this, 'resizeTimer'));
    $.data(this, 'resizeTimer', setTimeout(function () {
        initNav();
    }, 200));
});

function initNav() {
    $('header .nav-toggler').unbind().click(function (e) {
        $('#nav_wrapper nav').toggleClass('toggle');
    });

    if ($(window).width() < 992) {
        if ($('#nav_wrapper nav').find('ul').length > 0) {
            $li = $('#nav_wrapper nav').find('li');

            $li.has('ul').children('a').unbind().click(function (e) {
                let $a = $(this);
                $(this).closest('ul').children('li').find('ul').each(function (i, ul) {
                    if ($(ul)[0] != $a.next('ul')[0]) {
                        $(ul).removeClass('open');
                    }
                });

                if (!$a.next('ul').hasClass('open')) {
                    e.preventDefault();
                    $a.next('ul').addClass('open');
                }
            });
        }
    } else {
        $('#nav_wrapper nav ul').removeClass('open');
    }


    $(window).unbind('scroll').scroll(function () {
        if ($(this).scrollTop() > 60) {
            $('body').addClass('fixed');
        } else {
            $('body').removeClass('fixed');
        }
    });
}

function initMatchHeight() {
    $('.matchheight').matchHeight();
}

function initSubscribe() {
    if ($('#subscribe').length > 0) {

        template = $('.animal_template').html();
        $('.animal_template').remove();

        $('.btn-add-animal').on('click', function (e) {
            e.preventDefault();
            $('#animal_container').append($(template));
            updateInputIndexes();
            initDeleteAnimal();
        });

        if($('#animal_container .animal_wrapper').length == 0){
            $('.btn-add-animal').click();
        }

        initDeleteAnimal();
    }
}

function updateInputIndexes() {
    $('#animal_container .animal_wrapper').each(function (i, el) {
        $(el).find('h3 span').text(i + 1);
        $(el).find('label.control-label').each(function () {
            $(this).attr('for', $(this).attr('for').replace(/animal\[\d+\]/, 'animal[' + i + ']'))
        });
        $(el).find('input, textarea').each(function () {
            $(this).attr('id', $(this).attr('name').replace(/animal\[\d+\]/, 'animal[' + i + ']').replace(/\[/g, '_').replace(/\]/g, ''));
            $(this).attr('name', $(this).attr('name').replace(/animal\[\d+\]/, 'animal[' + i + ']'))
        });
    });
}

function initDeleteAnimal() {
    $('#animal_container .animal_wrapper').each(function(i, el){
       $(el).find('.delete_animal').unbind().on('click', function(e){
           e.preventDefault();
           $(el).remove();
       });
    });
}